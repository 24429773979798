import React from 'react';
import {
  BottomContainer,
  Logo,
  LogoContainer,
  MainContainer,
  InputWithIconContainer,
  StyledButton,
  TopContainer,
  TopContainerText,
  SecretInput,
  GoogleAuthContainer,
  TextButton,
} from './styles';
import logo from '../assets/logo_white_background.png';
import { CopyIcon } from './CopyIcon';
import { useSnackbar } from 'react-simple-snackbar';
import { neutralSnackbarConfig } from '../common/snackbar-configuration';

export const GoogleAuthRecoveryPage = ({ onContinue, code }) => {
  const [openNeutralSnackbar] = useSnackbar(neutralSnackbarConfig);
  const [isCopied, setIsCopied] = React.useState(false);
  return (
    <MainContainer>
      <TopContainer>
        <LogoContainer>
          <Logo src={logo} />
        </LogoContainer>
        <TopContainerText>
          In the event that you need to login without your device you'll need recovery code. Take a note and keep this
          somewhere safe:
        </TopContainerText>
        <GoogleAuthContainer>
          <InputWithIconContainer>
            <SecretInput>{code}</SecretInput>
            <CopyIcon onClick={onCopy} />
          </InputWithIconContainer>
        </GoogleAuthContainer>
      </TopContainer>
      <BottomContainer>
        <StyledButton onClick={onContinue} disabled={!isCopied}>
          Continue
        </StyledButton>
        <TextButton onClick={onContinue}>Skip</TextButton>
      </BottomContainer>
    </MainContainer>
  );

  async function onCopy() {
    await navigator.clipboard.writeText(code);
    setIsCopied(true);
    openNeutralSnackbar('Recovery code copied to clipboard');
  }
};
