import React from 'react';
import { MainContainer } from './styles';
import styled from 'styled-components';
import { Img } from 'react-image';
import loadingAnimation from './../assets/zebb_loading.gif';

const CenteredContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #502e94;
  color: white;
  object-fit: contain;
`;

export const LoadingPage = () => {
  return (
    <MainContainer>
      <CenteredContainer>
        <Img src={loadingAnimation} width={'100%'} />
      </CenteredContainer>
    </MainContainer>
  );
};
