import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import { navigate } from '@reach/router'


const Svg = styled(Icon)`
  width: 24px;
  height: 24px;
`;


export const BackIcon = (props) => (
  <Svg viewBox="0 0 24 24" onClick={() => navigate(-1)} {...props}>
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
  </Svg>
);
