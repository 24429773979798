export const errorSnackbarConfig = {
  position: 'top-center',
  style: {
    textAlign: 'center',
    background: '#FFDDDD',
    color: '#393247',
  },
  closeStyle: {
    color: '#393247',
  },
};

export const neutralSnackbarConfig = {
  position: 'top-center',
  style: {
    textAlign: 'center',
    background: '#393247',
    color: '#ffffff',
  },
  closeStyle: {
    color: '#ffffff',
  },
};
