import React from 'react';
import PhoneInput from 'react-phone-input-2';
import '../styles/phone-input.css';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import logo from '../assets/logo_white_background.png';

import {
  BottomContainer,
  ButtonsContainer,
  Label,
  Logo,
  LogoContainer,
  MainContainer,
  PhoneInputContainer,
  PhoneNumberFormContainer,
  StyledButton,
  TopContainer,
  TopContainerText,
} from './styles';

/**
 * A component for enrolling phone number to the authenticated user
 * @returns {JSX.Element}
 * @constructor
 */
export const SmsOtpEnrollmentPage = ({ associatePhoneNumberAction, phoneNumber, setPhoneNumber }) => {
  const isValidPhoneNumber = isMobilePhone('+' + phoneNumber);
  return (
    <MainContainer>
      <TopContainer>
        <LogoContainer>
          <Logo src={logo} />
        </LogoContainer>
        <TopContainerText>Enter Your Phone Number</TopContainerText>
        <TopContainerText>
          We'll send a 6-digit verification code.
          <br />
          Standard SMS or data rates may apply.
        </TopContainerText>
        <PhoneNumberFormContainer>
          <Label>Mobile Number</Label>
          <PhoneInputContainer>
            <PhoneInput
              country={'in'}
              value={phoneNumber}
              onChange={(phoneNumber) => {
                setPhoneNumber(phoneNumber);
              }}
              onlyCountries={['in', 'us', 'ph', 'rs']}
            />
          </PhoneInputContainer>
        </PhoneNumberFormContainer>
      </TopContainer>
      <BottomContainer>
        <ButtonsContainer>
          <StyledButton
            disabled={!isValidPhoneNumber || associatePhoneNumberAction.isPending}
            onClick={() => {
              associatePhoneNumberAction.run(`+${phoneNumber}`);
            }}
          >
            {associatePhoneNumberAction.isPending ? 'Loading...' : 'Send Code'}
          </StyledButton>
        </ButtonsContainer>
      </BottomContainer>
    </MainContainer>
  );
};
