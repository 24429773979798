import React, { useRef } from 'react';
import {
  BottomContainer,
  Label,
  Logo,
  LogoContainer,
  MainContainer,
  GoogleAuthContainer,
  StyledButton,
  TopContainer,
  TopContainerText,
  SecretInput,
  InputWithIconContainer,
  TextButton,
} from './styles';
import logo from '../assets/logo_white_background.png';
import styled from 'styled-components';
import QRCode from 'qrcode';
import { useSnackbar } from 'react-simple-snackbar';
import { CopyIcon } from './CopyIcon';
import { neutralSnackbarConfig } from '../common/snackbar-configuration';
import useAutoScrollBottom from '../hooks/useAutoScrollBottom'

const Input = styled.input`
  outline: none;
  background: transparent;
  border: none;
  border-bottom: 2px solid #9c7ae1;
  height: 45px;
  color: white;
  font-size: 18px;
  text-align: left;
  font-weight: 300;
  font-family: Roboto, serif;
  letter-spacing: 20px;

  &:active &:focus-visible {
    border: none;
  }
`;

export const GoogleAuthEnrollment = ({ submitGoogleAuthOtp, googleAuthResponse, onFinishMfa }) => {
  const canvasRef = useRef();
  const [openNeutralSnackbar] = useSnackbar(neutralSnackbarConfig);
  React.useEffect(() => {
    QRCode.toCanvas(canvasRef.current, googleAuthResponse.barcode_uri, { width: 150 });
  }, []);
  const [otpCode, setOtpCode] = React.useState('');
  useAutoScrollBottom()
  return (
    <form onSubmit={onSubmit}>
      <MainContainer>
        <TopContainer>
          <LogoContainer>
            <Logo src={logo} />
          </LogoContainer>
          <TopContainerText>Scan this QR code with Google Authenticator (or similar) app:</TopContainerText>
          <GoogleAuthContainer>
            <canvas ref={canvasRef}></canvas>
            <div className="inner">
              <Label>Secret</Label>
              <InputWithIconContainer>
                <SecretInput>{googleAuthResponse.secret}</SecretInput>
                <CopyIcon onClick={onCopy} />
              </InputWithIconContainer>
            </div>
            <div className="inner">
              <Label>Verification Code</Label>
              <Input autoFocus maxLength={6} inputMode={'numeric'} onChange={(e) => setOtpCode(e.target.value)} />
            </div>
          </GoogleAuthContainer>
        </TopContainer>
        <BottomContainer>
          <StyledButton
            disabled={submitGoogleAuthOtp.isPending || otpCode < 6}
            onClick={() => {
              submitGoogleAuthOtp.run(otpCode);
            }}
          >
            {submitGoogleAuthOtp.isPending ? 'Loading...' : 'Continue'}
          </StyledButton>
          <TextButton onClick={onFinishMfa}>Skip</TextButton>
        </BottomContainer>
      </MainContainer>
    </form>
  );

  async function onCopy() {
    await navigator.clipboard.writeText(googleAuthResponse.secret);
    openNeutralSnackbar('Secret copied to clipboard');
  }

  function onSubmit(e) {
    e.preventDefault();
    if (!submitGoogleAuthOtp.isPending && otpCode === 6) {
      // console.log('trgger me')
      submitGoogleAuthOtp.run(otpCode);
    }
  }
};
