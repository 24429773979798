import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import Copy from '../assets/copy.png'


const Svg = styled(Icon)`
  width: 2px;
  height: 2px;
  fill: white;
`;

const Image = styled.div`
  background-size: contain;
  background-image: url(${Copy});
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
`

export const CopyIcon = (props) => (
  <Image {...props} />
);
