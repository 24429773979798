import React from 'react';
import styled from 'styled-components';
import SecuredLogo from '../assets/secured.png';
import { MainContainer, TextButton, BottomContainer } from './styles'

const StyledButton = styled.button`
  background: #ffffff;
  color: #502e94;
  border-radius: 200px;
  padding: 10px 40px;
  font-size: 18px;
  border: none;
  font-weight: 600;
  width: 200px;
`;


const Header = styled.h3`
  color: white;
`;

const Sub = styled.p`
  padding: 0 20px;
  text-align: center;
  color: white;
`;
const TopContainer = styled.div`
  padding-top: 80px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;


const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #502e94;
  color: white;
  object-fit: contain;
`;


/**
 * A page component that renders the the screen to prompt users to verify their email address
 * @param onVerify
 * @returns {JSX.Element}
 * @constructor
 */

export const GoogleAuthenticatorPage = ({ onFinishMfa, enrollGoogleAuthAction }) => {
  return (
    <MainContainer>
      <TopContainer>
        <ImageContainer>
          <img width="50%" src={SecuredLogo} />
        </ImageContainer>
        <Header>Secure your account</Header>
        <Sub>For account security do you want to Setup google authenticator app?</Sub>
      </TopContainer>
      <BottomContainer>
        <StyledButton onClick={() => enrollGoogleAuthAction.run()}>Yes</StyledButton>
        <TextButton onClick={onFinishMfa}>I'll do it later</TextButton>
      </BottomContainer>
    </MainContainer>
  );
};
