const ENVIRONMENTS = {
  development: {
    clientId: 'IF6JOEeLM3FxHGUmj3CrUvA4v8XpVU8Q',
    domain: 'auth.zebpay.co',
    audience: 'https://zebfi-sb.eu.auth0.com/mfa/',
  },

  staging: {
    clientId: '5NVhgu8qxoAhQC2RpNN8WyMnn4MWtedt',
    domain: 'auth.zebuat.com',
    audience: 'https://z2-stage.us.auth0.com/mfa/',
  },

  // main: {
  // 	clientId: '',
  // 	domain: 'mfa.zebpay.com',
  // audience: '',
  // },
};

export const config = ENVIRONMENTS[process.env.REACT_APP_ENV];
