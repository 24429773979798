import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-flow: column;
  background-color: #502e94;
  width: 100vw;
  height: 100vh;
`;

export const TopContainer = styled.div`
  padding: 30px 20px 0 20px;
  background-color: #502e94;
  color: white;
`;

export const BottomContainer = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #502e94;
  padding-bottom: 40px;
`;

export const ButtonsContainer = styled.div`
  position: fixed;
  bottom: 50px;
  @media (max-height: 490px) {
    display: none;
  }
`;

export const TopContainerText = styled.div`
  margin-bottom: 22px;
  text-align: center;
  font-weight: 300;
`;

export const PhoneNumberFormContainer = styled.div`
  margin: 50px 10px;
  display: flex;
  flex-direction: column;
`;

export const GoogleAuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin-top: 20px;
  }
  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  padding-bottom: 20px;
`;

export const StyledButton = styled.button`
  background: white;
  color: #502e94;
  border-radius: 200px;
  padding: 10px 40px;
  font-size: 18px;
  border: none;
  font-weight: 400;
  font-family: Roboto, serif;
  bottom: 10px;

  &:disabled {
    background: lightgrey;
    color: rgba(80, 46, 148, 0.4);
  }
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 12px;
  font-family: Roboto, serif;
  color: #9c7ae1;
`;

export const PhoneInputContainer = styled.div`
  margin-top: 3px;
`;

export const LogoContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  margin-bottom: 20px;
`;

export const Logo = styled.img`
  height: 100%;
`;

export const SecretInput = styled.span`
  padding: 5px;
  font-size: 13px;
  color: white;
  margin-right: 5px;
  border-radius: 5px;
`;

export const InputWithIconContainer = styled.div`
  padding-top: 10px;
  display: flex;
  align-items: center;
`;

export const TextButton = styled.div`
  margin: 20px 0 0 0;
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto, serif;
  color: white;
  border-radius: 200px;
  text-align: center;
`;